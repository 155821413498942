import styles from "styles/components/ArticleStamps.module.scss";
import Image from "next/image";

interface Props {
    stamps: {
        "shamelessly-stolen": boolean;
        "creative-effectiveness": boolean;
        "best-practice": boolean;
    };
}

const ArticleStamps = ({ stamps }: Props) => {
    return (
        <div className="stamps">
            {(stamps["best-practice"] ||
                stamps["shamelessly-stolen"] ||
                stamps["creative-effectiveness"]) && (
                <ul className={`${styles.fileList} fileList`}>
                    {stamps["best-practice"] && (
                        <li className="best-practice">
                            <div className={styles.stamp}>
                                <Image
                                    src="/images/stamps-best-practices.png"
                                    alt="Best Practices"
                                    width={26}
                                    height={26}
                                />
                            </div>
                            <span className="stamp-label">Best Practice</span>
                        </li>
                    )}
                    {stamps["shamelessly-stolen"] && (
                        <li className="shamelessly-stolen">
                            <div className={styles.stamp}>
                                <Image
                                    src="/images/stamps-shamelessly-stolen.png"
                                    alt="Shamelessly Stolen"
                                    width={26}
                                    height={26}
                                />
                            </div>
                            <span className="stamp-label">Shamelessly Stolen</span>
                        </li>
                    )}
                    {stamps["creative-effectiveness"] && (
                        <li className="creative-effectiveness">
                            <div className={styles.stamp}>
                                <Image
                                    src="/images/stamps-creative-effectiveness.png"
                                    alt="Creative Effectiveness"
                                    width={26}
                                    height={26}
                                />
                            </div>
                            <span className="stamp-label">Creative Effectiveness</span>
                        </li>
                    )}
                </ul>
            )}
        </div>
    );
};

export default ArticleStamps;
