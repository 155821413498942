// Sample Data
export const dataTopics = [
  { title: "Delivery", value: 3 },
  { title: "FIFA World Cup Campaign 2022", value: 20 },
  { title: "Food & Menu", value: 11 },
  { title: "Global Brand Leadership", value: 2 },
  { title: "Global Family Marketing", value: 9 },
  { title: "Global Loyalty", value: 17 },
  { title: "Global Media", value: 1 },
  { title: "Global Strategic Insights", value: 10 },
  { title: "Social Media", value: 15 },
  { title: "Brands", value: 6 },
  { title: "Diversity, Equity, and Inclusion in Marketing", value: 22 }
];

export const dataMarketsSmall = [
  {
    label: "American Samoa",
    value: 14,
  },
  {
    label: "Andorra",
    value: 1,
  },
  {
    label: "Arcos Dorados BU",
    value: 2,
  },
  {
    label: "Argentina",
    value: 3,
  },
  {
    label: "Belarus",
    value: 4,
  },
  {
    label: "Brazil",
    value: 5,
  },
  {
    label: "Canada",
    value: 6,
  },
  {
    label: "Fiji",
    value: 7,
  },
  {
    label: "Japan",
    value: 8,
  },
  {
    label: "New Zealand",
    value: 9,
  },
  {
    label: "Philippines",
    value: 10,
  },
  {
    label: "Spain",
    value: 11,
  },
  {
    label: "Transnational",
    value: 12,
  },
  {
    label: "United States",
    value: 13,
  },
];

export const dataMarkets = [
  'Global',
  'American Samoa',
  'Andorra',
  'Arcos Dorados BU',
  'Argentina',
  'Aruba',
  'Asia BU',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Belarus',
  'Belgium',
  'Belize',
  'Bolivia',
  'Bosnia & Herzegovina Fed. Of',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Canada',
  'Chile',
  'China',
  'Colombia',
  'Costa Rica',
  'Croatia',
  'Curacao',
  'Cyprus',
  'Czechia',
  'Denmark',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Estonia',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'Georgia',
  'Germany',
  'Greece',
  'Guadeloupe',
  'Guatemala',
  'GCC (Gulf Cooperation Council)',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'IDL Europe',
  'India',
  'Indonesia',
  'IOM BU',
  'Iran',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kuwait',
  'Latin America BU',
  'Latvia',
  'Lebanon',
  'Lithuania',
  'Luxembourg',
  'Macau',
  'Malaysia',
  'Malta',
  'Martinique',
  'Mauritius',
  'MEA BU',
  'Mexico',
  'Moldova',
  'Morocco',
  'Netherlands',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Nigeria',
  'Norway',
  'Oman',
  'Pakistan',
  'Panama',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Reunion',
  'Romania',
  'Russia',
  'Saudi Arabia',
  'Serbia',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'South Africa',
  'South Korea',
  'Spain',
  'Sri Lanka',
  'St. Maarten',
  'Suriname',
  'Sweden',
  'Switzerland',
  'Tahiti',
  'Taiwan',
  'Thailand',
  'Trinidad/Tobago',
  'Turkey',
  'United Arab Emirates',
  'Ukraine',
  'United Kingdom',
  'Uruguay',
  'United States',
  'Venezuela',
  'Vietnam',
  'Virgin Islands',
  'Western Samoa',
];

export const customCategories = [
  'Masterbrand',
  'McFlurry',
  'MyMcDonald’s',
  'Happy Meal®',
  'McDelivery',
  'McCafé®',
  'Premium',
  'Packaging'
];

export const blockedDomains = [
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "aol.com",
  "hotmail.co.uk",
  "hotmail.fr",
  "msn.com",
  "yahoo.fr",
  "wanadoo.fr",
  "orange.fr",
  "comcast.net",
  "yahoo.co.uk",
  "yahoo.com.br",
  "yahoo.co.in",
  "live.com",
  "rediffmail.com",
  "free.fr",
  "gmx.de",
  "web.de",
  "yandex.ru",
  "ymail.com",
  "libero.it",
  "outlook.com",
  "uol.com.br",
  "bol.com.br",
  "mail.ru",
  "cox.net",
  "hotmail.it",
  "sbcglobal.net",
  "sfr.fr",
  "live.fr",
  "verizon.net",
  "live.co.uk",
  "googlemail.com",
  "yahoo.es",
  "ig.com.br",
  "live.nl",
  "bigpond.com",
  "terra.com.br",
  "yahoo.it",
  "neuf.fr",
  "yahoo.de",
  "alice.it",
  "rocketmail.com",
  "att.net",
  "laposte.net",
  "facebook.com",
  "bellsouth.net",
  "yahoo.in",
  "hotmail.es",
  "charter.net",
  "yahoo.ca",
  "yahoo.com.au",
  "rambler.ru",
  "hotmail.de",
  "tiscali.it",
  "shaw.ca",
  "yahoo.co.jp",
  "sky.com",
  "earthlink.net",
  "optonline.net",
  "freenet.de",
  "t-online.de",
  "aliceadsl.fr",
  "virgilio.it",
  "home.nl",
  "qq.com",
  "telenet.be",
  "me.com",
  "yahoo.com.ar",
  "tiscali.co.uk",
  "yahoo.com.mx",
  "voila.fr",
  "gmx.net",
  "mail.com",
  "planet.nl",
  "tin.it",
  "live.it",
  "ntlworld.com",
  "arcor.de",
  "yahoo.co.id",
  "frontiernet.net",
  "hetnet.nl",
  "live.com.au",
  "yahoo.com.sg",
  "zonnet.nl",
  "club-internet.fr",
  "juno.com",
  "optusnet.com.au",
  "blueyonder.co.uk",
  "bluewin.ch",
  "skynet.be",
  "sympatico.ca",
  "windstream.net",
  "mac.com",
  "centurytel.net",
  "chello.nl",
  "live.ca",
  "aim.com",
  "bigpond.net.au",
];

export const blockedRegDomains = [
  "apmea.mcd.com",
  "at.mcd.com",
  "au.licensee.mcd.com",
  "au.mcd.com",
  "au.stores.mcd.com",
  "be.mcd.com",
  "dk.mcd.com",
  "ee.mcd.com",
  "es.mcd.com",
  "eu.mcd.com",
  "external.mcd.com",
  "ffcrew.com",
  "fi.mcd.com",
  "foodfolk.com",
  "fr.mcd.com",
  "fra.at.mcd.com",
  "fra.dk.mcd.com",
  "fra.es.mcd.com",
  "franchisee.pl.mcd.com",
  "gr.mcd.com",
  "hk.mcd.com",
  "hr.mcd.com",
  "hu.mcd.com",
  "id.mcd.com",
  "ie.mcd.com",
  "it.mcd.com",
  "jed.mcd.com",
  "jp.mcd.com",
  "kr.licensee.mcd.com",
  "kr.mcd.com",
  "kw.mcd.com",
  "lt.mcd.com",
  "lv.mcd.com",
  "ma.mcd.com",
  "mcd.com",
  "mcdonalds.ca",
  "mcdonalds.co.nz",
  "mcdonalds.co.uk",
  "mcdonaldscorp.mail.onmicrosoft.com",
  "mcdonaldscorp.onmicrosoft.com",
  "mcdonaldsindia.net",
  "mcdonalds-uae.com",
  "mcthai.co.th",
  "mgd.mcd.com",
  "mt.mcd.com",
  "mum.in.mcd.com",
  "my.mcd.com",
  "net.mcdonalds.fr",
  "nl.mcd.com",
  "no.mcd.com",
  "nz.licensee.mcd.com",
  "nz.mcd.com",
  "nz.stores.mcd.com",
  "partners.mcd.com",
  "pl.mcd.com",
  "post.mcdonalds.ca",
  "pt.mcd.com",
  "rest.mcdonalds.fr",
  "restaurant.at.mcd.com",
  "restaurant.de.mcd.com",
  "restaurant.gr.mcd.com",
  "restaurant.it.mcd.com",
  "ro.mcd.com",
  "rs.mcd.com",
  "se.mcd.com",
  "sg.mcd.com",
  "si.mcd.com",
  "sk.mcd.com",
  "store.de.mcd.com",
  "temporary.mcd.com",
  "th.mcd.com",
  "tw.licensee.mcd.com",
  "tw.mcd.com",
  "ua.mcd.com",
  "uk.mcd.com",
  "us.cosmcs.com",
  "us.mcd.com",
  "us.stores.mcd.com",
  "waad.mcd.com",
  "yu.mcd.com",
  "za.mcd.com",
  "zz.mcd.com"
]