// Next
import Image from "next/image";

// Styles
import styles from "styles/components/AwardsBadge.module.scss";

// Functions
function capitalizeFirstLetter(string: any) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

type Props = {
    award?: any,
    year?: any
};

const AwardsBadge = ({ award, year }: Props) => {
    let awardLabel: any = award ? capitalizeFirstLetter(award) : '';
    if (award) {
        if (award === 'finalist') {
            awardLabel = 'Awards Finalist';
        } else if (award === 'winner') {
            awardLabel = 'Winner';
        } else if (award === 'grand-prix') {
            awardLabel = 'Grand Prix Winner';
        } else if (award === 'creative-bravery') {
            awardLabel = 'Creative Bravery Winner';
        } else if (award === 'dei-excellence-recognition') {
			awardLabel = 'DEI Excellence Recognition'
		} else if (award === 'leveling-up-in-ce-winner') {
			awardLabel = 'Leveling Up in CE Winner'
		} else {
            awardLabel += ' Winner'
        }
    }

    if (award) {
        return (
            <div className={styles.award}>
                <div className={styles.iconContainer}>
                    <Image
                        unoptimized={true}
                        src={`/images/creative-effectiveness/award-${award === 'winner' ? 'gold' : award}.svg`}
                        alt={`${award} award`}
                        layout="fill"
                        objectFit="cover"
                    />
                </div>
                <div className={styles.awardDetails}>
                    <p>
                        <span className={styles.awardLabel}>{`${awardLabel}`}</span>
                    </p>
                </div>
            </div>
        )
    } else {
        return <></>
    }
}

export default AwardsBadge;