import React, { useRef, useState, useEffect } from "react";
import cardStyles from "styles/components/Card.module.scss";
import modalStyle from "styles/components/Modal.module.scss";
import stickyCardStyles from "styles/components/StickyCard.module.scss";
import IconBookmark from "../../images/icon-bookmark.svg";
import { createPortal } from "react-dom";
import BookmarkPopup from "./BookmarkPopup";
import { IPageApi } from "../../helpers/interfaces";
import { toggleIsBookmarked } from "../../helpers/functions";

const Modal = (props: any) => {
  const [container] = React.useState(() => {
    // This will be executed only on the initial render
    // https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
    return document.createElement('div');
  });

  React.useEffect(() => {
    container.classList.add('root-protal')
    document.body.appendChild(container)
    return () => {
      document.body.removeChild(container)
    }
  }, [])

  return (
    <>
      {createPortal(
        props.children,
        container,
      )}
    </>
  )
}

type Props = {
  article_id: number;
  article_slug: any;
  isBookmarked: boolean;
  setIsBookmarked: any;
  setToggleArticle: any;
  showLabel?: boolean;
};

function BookmarkButton({ article_id, article_slug, isBookmarked, setIsBookmarked, setToggleArticle, showLabel = false }: Props) {
  const [showPopup, setShowPopup] = useState(false);
  const [isUnsortedDefault, setIsUnsortedDefault] = useState(false);
  const [isNewBookmark, setIsNewBookmark] = useState(false);
  const [newBookmark, setNewBookmark] = useState(false);

  const handleBookmarked = async (id: number) => {
    const bookmarkResponse = await toggleIsBookmarked(article_id);
    // console.log("bookmarkResponse", bookmarkResponse);
    if (bookmarkResponse.loaded && !bookmarkResponse.error) {
      // console.log(bookmarkResponse);
      // console.log(`bookmark toggle successful for article ${id}`);
      isBookmarked ? setIsBookmarked(false) : setIsBookmarked(true);
    }
  };

  return (
    <>
      <span
        className={`${cardStyles.bookmark} ${isBookmarked ? cardStyles.bookmarked : ""
          }`}>
        <button
          aria-label="Bookmark"
          // data-slug={slug}
          className={`bookmark ${isBookmarked
            ? `is-bookmarked ${stickyCardStyles.isBookmarked}`
            : `not-bookmarked ${stickyCardStyles.notBookmarked}`
            } bookmark-${article_id}`}
          onClick={() => {
            // handleBookmarked(article_id);
            // window.dataLayer.push({"bookmarked_article" : `/articles/${slug}`});
            showPopup ? setShowPopup(false) : setShowPopup(true);
            if (setToggleArticle !== null) {
              setToggleArticle(true);
            }
            if (!isBookmarked) {
              setIsBookmarked(true);
              handleBookmarked(article_id);
              setIsUnsortedDefault(true);
              setIsNewBookmark(true);
              setNewBookmark(true);
            }
          }}>
          <span className={`bookmark ${isBookmarked
            ? `is-bookmarked ${stickyCardStyles.isBookmarked}`
            : `not-bookmarked ${stickyCardStyles.notBookmarked}`
            } bookmark-${article_id}`}></span>
          <IconBookmark />
          {showLabel && (<p>Save</p>)}
        </button>
      </span>
      {showPopup &&
        <Modal>
          <BookmarkPopup
            article_id={article_id}
            article_slug={article_slug}
            setShowPopup={setShowPopup}
            setToggleArticle={setToggleArticle}
            isBookmarked={isBookmarked}
            setIsBookmarked={setIsBookmarked}
            isUnsortedDefault={isUnsortedDefault}
            setIsUnsortedDefault={setIsUnsortedDefault}
            newBookmark={newBookmark}
            setNewBookmark={setNewBookmark}
            isNewBookmark={isNewBookmark}
            setIsNewBookmark={setIsNewBookmark}
          />
        </Modal>
      }
    </>
  )
}

export default BookmarkButton;
