import React, { useRef, useState, useEffect } from "react";
import modalStyle from "../../styles/components/Modal.module.scss";
import {
  deleteBookmarkFolder,
  fetchBookmarksFolders,
  fetchData,
  toggleBookmarkFolder,
  toggleIsBookmarked,
  checkReadStatus
} from "../../helpers/functions";
import { IfaceAllNewestResponse, IPageApi } from "../../helpers/interfaces";
import { useRecoilState } from "recoil";
import { currentUserState } from "../../state/atoms";
import Close from "../../images/ui-plus-close.svg";

type Props = {
  article_id: number;
  article_slug: any;
  setShowPopup: any;
  setToggleArticle: any;
  isBookmarked: boolean;
  setIsBookmarked: any;
  isUnsortedDefault: boolean;
  setIsUnsortedDefault: any;
  newBookmark: any;
  setNewBookmark: any;
  isNewBookmark: any;
  setIsNewBookmark: any;
};

const FolderOption = (props: any) => {
  const folder = props.folder;
  const article_id = props.article_id;
  let checked = props.checked;

  const toggleActiveBookmarks = () => {
    let toggledBookmarks = [...props.toggledBookmarks];
    if (toggledBookmarks.indexOf(folder.id) === -1) {
      toggledBookmarks.push(folder.id);
    } else {
      toggledBookmarks.splice(toggledBookmarks.indexOf(folder.id), 1);
    }
    if (props.isUnsortedDefault) {
      props.setIsUnsortedDefault(false);
    }
    if (props.isNewBookmark && folder.folder_name.toLowerCase() !== 'unsorted') {
      const unsortedFolder = document.getElementById('unsorted-option-' + article_id);
      if (unsortedFolder) {
        toggledBookmarks = [folder.id];
        props.setIsNewBookmark(false);
      }
    }
    props.setToggledBookmarks(toggledBookmarks);
  };

  if (folder && folder.folder_name.toLowerCase() === 'unsorted' && props.isUnsortedDefault && !checked) {
    const matchedContent = folder.folder_contents.filter((content: any) => {
      return content.content_id === article_id;
    });

    if (matchedContent.length === 0) {
      toggleActiveBookmarks();
      checked = true;
    }
  }

  return (
    <li className={`${checked ? modalStyle.checked : ''}`} key={'folder-option-' + folder.id}>
      <label id={`unsorted-option-${article_id}`} className={`folder-option folder-option-${folder.id}`}
      htmlFor={`bookmark-${folder.id}`} data-id={folder.id}>{folder.folder_name}</label>
      <div className={modalStyle.inputContainer}>
        <div className={'text-container'}>
          <p className={`${modalStyle.title}`}>{folder.folder_name}</p>
          <p className={`${modalStyle.count}`}>{folder.folder_contents_count} {folder.folder_contents_count !== 1 ? ' Items' : ' Item'}</p>
        </div>
        <input id={`bookmark-${folder.id}`} type={'checkbox'} name={'folders'} value={folder.folder_name} onChange={(e) => {
        }} onClick={(e: any) => {
          if(e.target.checked) {
            props.setShowFolderWarning(false);
          }
          toggleActiveBookmarks();
        }} checked={checked} />
      </div>
    </li>
  )
}

function BookmarkPopup({ article_id, article_slug, isBookmarked, setIsBookmarked, setShowPopup, setToggleArticle, isUnsortedDefault, setIsUnsortedDefault, newBookmark, setNewBookmark, isNewBookmark, setIsNewBookmark }: Props) {
  const [thisSlug, setThisSlug] = useState("");
  const [bookmarkFolders, setBookmarkFolders] = useState<any>([]);
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
  let userInfo = { ...currentUser };
  const [toggledBookmarks, setToggledBookmarks] = useState<any>([]);

  const getFoldersData = async () => {
    if (userInfo.id !== null) {
      const newestPostsApi: IfaceAllNewestResponse = await fetchBookmarksFolders(userInfo.id);
      if (bookmarkFolders !== newestPostsApi.res.data) {
        setBookmarkFolders(newestPostsApi.res.data);
      }
    }
  };

  useEffect(() => {
    getFoldersData(); // get initial data
  }, [currentUser]);

  const handleBookmarked = async (id: number) => {
    const bookmarkResponse = await toggleIsBookmarked(article_id);
    // console.log("bookmarkResponse", bookmarkResponse);
    if (bookmarkResponse.loaded && !bookmarkResponse.error) {
      // console.log(`bookmark toggle successful for article ${id}`);
      isBookmarked ? setIsBookmarked(false) : setIsBookmarked(true);
    }
  };

  const [bookmarkID, setBookmarkID] = useState<number>(-1);
  const [activeFolders, setActiveFolders] = useState<any>([]);
  const [showFolderWarning, setShowFolderWarning] = useState<boolean>(false);
  const getArticlesData = async () => {
    if (userInfo.id !== null) {
      const articleData = await checkReadStatus(article_id);
      if (articleData.loaded && !articleData.error && articleData.res.data.length > 0) {
        if(articleData.res.data[0]['user_bookmarked_id'] && bookmarkID !== articleData.res.data[0]['user_bookmarked_id']) {
          setBookmarkID(articleData.res.data[0]['user_bookmarked_id']);
        }
        if(articleData.res.data[0]['user_bookmark_folders'] && activeFolders.length !== articleData.res.data[0]['user_bookmark_folders'].length) {
          setActiveFolders(articleData.res.data[0]['user_bookmark_folders'])
        }
      }
    }
  };

  useEffect(() => {
    getArticlesData();
  }, [bookmarkID]);

  const toggleAllBookmarkFolders = async () => {
    if (toggledBookmarks.length > 0) {
      toggledBookmarks.forEach((id: any) => {
        if (userInfo.id !== null) {
          toggleBookmarkFolder(userInfo.id, id, bookmarkID);
        }
      })
    }
    setToggledBookmarks([]);
    setShowPopup(false);
    if (setToggleArticle !== null) {
      setToggleArticle(false);
    }
  }

  const closeWithoutSaving = () => {
    setToggledBookmarks([]);
    setShowPopup(false);
    if (setToggleArticle !== null) {
      setToggleArticle(false);
    }
    if (newBookmark) {
      console.log('delete bookmark');
      handleBookmarked(article_id);
    }
  }

  return (
    <div className={`${modalStyle.dialogLayer}`}>
      <div className={`${modalStyle.dialogLayerInner}`}>
        <div className={`${modalStyle.background}`} onClick={closeWithoutSaving}></div>
        <div role={'dialog'} id={'folder-dialog'} aria-modal={true} className={`${modalStyle.dialogBox} ${modalStyle.folderEditContainer}`}>
          <div className={`${modalStyle.headerContainer}`}>
            <h2>Select Bookmark Folder</h2>
            <button className={`${modalStyle.closeBtn}`} onClick={closeWithoutSaving}>
              <span>Close</span>
              <Close />
            </button>
          </div>
          <form id={'folder-form-' + article_id}>
            <ul>
              {bookmarkFolders ?.map((folder: any) => {
                let checked = false;
                const matchedContent = folder.folder_contents.filter((content: any) => {
                  return content.content_id === article_id;
                });
                if ((matchedContent.length > 0 && toggledBookmarks.indexOf(folder.id) === -1) || matchedContent.length === 0 && toggledBookmarks.indexOf(folder.id) > -1) {
                  checked = true;
                }
                return (
                  <FolderOption
                    article_id={article_id}
                    folder={folder}
                    key={'folder-option-' + folder.id}
                    toggledBookmarks={toggledBookmarks}
                    setToggledBookmarks={setToggledBookmarks}
                    checked={checked}
                    isUnsortedDefault={isUnsortedDefault}
                    setIsUnsortedDefault={setIsUnsortedDefault}
                    isNewBookmark={isNewBookmark}
                    setIsNewBookmark={setIsNewBookmark}
                    setShowFolderWarning={setShowFolderWarning}
                  />
                )
              })}
            </ul>
            {showFolderWarning && <p className={modalStyle.folderWarning}>Please select a folder</p>}
            <button type={'submit'} className={'btn'} onClick={(e) => {
              e.preventDefault();
              let hasSelectedFolders: boolean = false;

              const form = document.getElementById('folder-form-' + article_id);
              if(form) {
                const folderOptions = form.querySelectorAll('input[type="checkbox"]');
                if(folderOptions) {
                  folderOptions.forEach((option: any) => {
                    if(option.checked) {
                      hasSelectedFolders = true;
                    }
                  })
                }
              }

              if(hasSelectedFolders) {
                toggleAllBookmarkFolders();
                setNewBookmark(false);
              } else {
                setShowFolderWarning(true);
              }
            }}>Save</button>
          </form>
          <div className={modalStyle.buttonContainer}>
            <button className={`${modalStyle.cancel}`} onClick={() => {
              setShowPopup(false);
              handleBookmarked(article_id);
            }}>Delete Bookmark</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BookmarkPopup;
